import React, { useState, useEffect } from "react";
import { ImFolderDownload } from "react-icons/im";
import { BiFilter } from "react-icons/bi";
import { chatsData } from "../data/whatsapp";
import Chat from "./Chat";

const Chats = ({ onSendData, onArchivedClick }) => {
  const [chats, setChats] = useState(chatsData);
  const [selectedChat, setSelectedChat] = useState(null);
  const [filter, setFilter] = useState(false);
  const [search, setSearch] = useState("");

  const sendDataToLeftMenu = () => {
    const data = selectedChat;
    onSendData(data);
  };

  useEffect(() => {
    if (selectedChat) {
      sendDataToLeftMenu();
    }
  }, [selectedChat]);

  useEffect(() => {
    const newChats = filter
      ? chatsData.filter((chat) => chat.unreadMsgs)
      : chatsData;

    setChats(newChats);
  }, [filter]);

  useEffect(() => {
    const filteredChats = chatsData.filter((chat) =>
      chat.contact.toLowerCase().includes(search.toLowerCase())
    );

    setChats(filteredChats);
  }, [search]);

  const handleChatClick = (contact) => {
    console.log(contact);
    setSelectedChat(contact);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className="flex flex-col overflow-y-scroll cursor-pointer h-100">
      <h1 className="text-white text-2xl p-2 font-semibold">Chats</h1>
      <div className="flex justify-between items-center h-[60px] px-2 -mt-2">
        <input
          type="text"
          placeholder="Search or start a new chat"
          className="rounded-lg bg-[#202d33] text-[#8796a1] text-sm font-light outline-none p-2 px-4 py-2 w-screen h-[35px] placeholder:text-[#8796a1] placeholder:text-sm placeholder:font-light"
          value={search}
          onChange={handleSearchChange}
        />

        <button
          className={`text-2xl m-2 p-1 rounded-full ${
            filter
              ? "bg-emerald-500 text-white rounded-full hover:bg-emerald-700"
              : "text-[#8796a1] hover:bg-[#3c454c]"
          }`}
          onClick={() => setFilter(!filter)}
        >
          <BiFilter />
        </button>
      </div>

      <div
        className="flex justify-between items-center w-100 min-h-[55px] px-3 hover:bg-[#202d33] cursor-pointer"
        onClick={onArchivedClick}
      >
        <div className="flex justify-around items-center w-[150px]">
          <span className="text-emerald-500 text-lg">
            <ImFolderDownload />
          </span>
          <h1 className="text-white">Archived</h1>
        </div>
        <p className="text-emerald-500 text-sm font-light">8</p>
      </div>

      {chats.map((chat) => (
        <Chat
          key={chat.id}
          pp={chat.pp}
          contact={chat.contact}
          msg={chat.msg}
          time={chat.time}
          unreadMsgs={chat.unreadMsgs}
          active={selectedChat === chat.contact}
          onClick={() => handleChatClick(chat.contact)}
        />
      ))}
    </div>
  );
};

export default Chats;
