import React from "react";

const Archived = () => {
  return (
    <div className="flex flex-col overflow-y-scroll cursor-pointer h-100">
      <h1 className="text-white text-2xl p-2 font-semibold">Archived</h1>
      <div className="relative flex flex-col items-center justify-center h-screen w-full bg-[#111a21] overflow-y-scroll ">
        <h2 className="text-gray-500">Not available</h2>
      </div>
    </div>
  );
};

export default Archived;
