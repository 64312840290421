import React, { useState,useEffect } from 'react';
import { IoIosArrowDropdownCircle } from 'react-icons/io';
import { IoCloseCircleSharp } from 'react-icons/io5';
import StatusTicks from './StatusTicks';
import MessageDialog from './MessageDialog';
import ReactionDialog from './ReactionDialog';
import ReactionManagementDialog from './ReactionManagementDialog';
import { FaFilePdf, FaFileWord, FaFileExcel, FaFilePowerpoint, FaFile } from 'react-icons/fa';
import PollMessage from './PollRe'; 


function Message({ msg, type,time, isLink, img, audio, sent, status, 
  profileImage, onProfilePicClick, 
  onEditMessage,onDeleteMessage,onPinMessage,onReplyMessage,replyingTo, file, poll
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);
  const [editMessage, setEditMessage] = useState(msg);
  const [isEditing, setIsEditing] = useState(false);
  const [isReactionDialogOpen, setIsReactionDialogOpen] = useState(false);
  const [isReactionManagementDialogOpen, setIsReactionManagementDialogOpen] = useState(false);
  const [selectedReaction, setSelectedReaction] = useState(null);
  
  const handleVote = (pollIndex, optionIndex) => {
    console.log(`Poll ${pollIndex} option ${optionIndex} selected`);
  };
  const handleIconClick = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleEditMessage = () => {
    setIsEditing(true);
    setIsDialogOpen(false);
  };

  const handleMessageChange = (e) => {
    setEditMessage(e.target.value);
  };

  const handleMessageSubmit = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onEditMessage(editMessage); 
      setIsEditing(false);
    }
  };
  const handleReply = () => {
    onReplyMessage({ id: msg.id, msg });
  };

  const handleDelete = (option) => {
    onDeleteMessage(option); 
    setIsDialogOpen(false);
    setSelectedReaction(null); 
  };
  const handleProfileClick = () => {
    setIsProfileDialogOpen(true);
  };
  const handleCloseProfileDialog = () => {
    setIsProfileDialogOpen(false);
  };
  
  const handleReact = () => {
    setIsDialogOpen(false);
    setIsReactionDialogOpen(true); 
  };

  const handleReactionSelect = (reaction) => {
    setSelectedReaction(reaction);
    setIsReactionDialogOpen(false);
  };
  const handleCloseReactionDialog = () => {
    setIsReactionDialogOpen(false);
  };
  const handleReactionManagementClick = () => {
    setIsReactionManagementDialogOpen(!isReactionManagementDialogOpen);
  };
  const handleRemoveReaction = () => {
    setSelectedReaction(null);
  };
  const handleCloseReactionManagementDialog = () => {
    setIsReactionManagementDialogOpen(false);
  };  

  const fileUrl = file ? URL.createObjectURL(file) : null;
  useEffect(() => {
    return () => {
      if (fileUrl) {
        URL.revokeObjectURL(fileUrl);
      }
    };
  }, [fileUrl]);
  
  const getFileIcon = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf':
        return <FaFilePdf className="text-red-600" />;
      case 'doc':
      case 'docx':
        return <FaFileWord className="text-blue-600" />;
      case 'xls':
      case 'xlsx':
        return <FaFileExcel className="text-green-600" />;
      case 'ppt':
      case 'pptx':
        return <FaFilePowerpoint className="text-orange-600" />;
      default:
        return <FaFile className="text-gray-600" />;
    }
  };
const messageMargin = selectedReaction ? 'mb-4' : 'my-2';
const isImage = img || (file && file.type.startsWith('image/'));
const messageType = isImage ? 'image' : file ? 'file' : audio ? 'audio' : isLink ? 'link': poll ? 'poll' : 'text';
  return (
    <div
      className={`relative flex flex-col rounded-md w-fit  ${messageMargin} p-2 ${
        sent ? 'bg-[#005c4b] ml-auto' : 'bg-[#202d33] mr-auto'
      }`}
    >
      <div className="relative flex flex-row items-start">
        {!sent && (
          <div className="relative">
            <img
              src={profileImage}
              alt="User Profile"
              className="w-8 h-8 rounded-full mr-2 cursor-pointer"
              onClick={handleProfileClick}
            />
            {isProfileDialogOpen && (
              <div className="absolute top-10 left-0 bg-opacity- p-4 rounded-md shadow-md w-48 z-50">
                <div className="relative">
                  <img
                    src={profileImage}
                    alt="Profile"
                    className="w-24 h-24 rounded-full mx-auto"
                  />
                 <IoCloseCircleSharp
                    className="absolute top-1 right-1 text-red-500 text-xl cursor-pointer"
                    onClick={handleCloseProfileDialog}
                  />
                </div>
                {/* Profile details here */}
              </div>
            )}
          </div>
        )}
        <div className="flex flex-col">
          <div className="flex flex-row justify-between items-center mb-2">
            <div className="flex-grow"></div>
            <div className="w-6 h-6" onClick={handleIconClick}>
              <IoIosArrowDropdownCircle className="text-[#8796a1] text-xl" />
            </div>
          </div>
          <div
            className="flex flex-col items-start max-w-[410px]"
            style={{ wordBreak: 'break-word' }}
          >
            {isEditing ? (
              <textarea
                value={editMessage}
                onChange={handleMessageChange}
                onKeyDown={handleMessageSubmit}
                className="w-full p-2 rounded-md text-black"
                autoFocus
              />
            ) : isImage ? (
              <div className="relative w-100">
                <img src={img || fileUrl} alt="message_content" className="rounded-md max-w-[270px] w-100" />
                <a href={img || fileUrl} download className="absolute bottom-2 right-2 text-white text-xs underline">
                  Download
                </a>
                <p className="absolute right-2 bottom-6 text-[#8796a1] text-[10px] min-w-[50px]">
                  {time}
                </p>
              </div>
            ) : file ? (
              <div className="flex flex-col items-center">
                <div className="flex items-center">
                  {getFileIcon(file.name)}
                  <span className="text-white text-sm ml-2">{file.name}</span>
                  <a href={fileUrl} download className="ml-2 text-[#53beec] text-sm underline">
                    Download
                  </a>
                </div>
                <p className="text-[#8796a1] text-[10px]">{time}</p>
              </div>
            ) : poll ? ( 
              <div className="flex flex-col">
              <PollMessage 
                poll={poll}
                onVote={(pollId, optionIndex) => handleVote(pollId, optionIndex)}
                sent={sent} 
              />
              <p className="text-[#8796a1] text-[10px] mt-2">{time}</p>
              </div>
            ): audio ? (
              <div className="flex flex-col items-start">
                <audio controls className="rounded-md max-w-[270px] w-300">
                  <source src={audio} type="audio/wav" />
                  Your browser does not support the audio element.
                </audio>
                <p className="text-[#8796a1] text-[10px]">{time}</p>
              </div>
            ) 
            : isLink ? (
              <a
                href={msg}
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#53beec] hover:text-[#53beec] focus:text-[#53beec] active:text-[#53beec] text-sm underline hover:underline"
              >
                {msg}
              </a>
            ) : (
              <div className="flex flex-col">
              <p className={`text-white text-sm ${msg.isDeleted ? 'text-gray-500' : ''}`}>
                {msg.isDeleted ? 'This message was deleted' : msg}
              </p>
              <p className={`text-[#8796a1] text-[10px] ${msg.isDeleted ? 'text-gray-500' : ''}`}>
                {msg.isDeleted ? '' : time}
              </p>

              </div>
            )}
          </div>
        </div>
      </div>
      {selectedReaction && (
          <div
            className="absolute -bottom-0 left-0 text-xl"
            style={{ transform: 'translateY(100%)' }}
            onClick={handleReactionManagementClick}
          >
            {selectedReaction}
          </div>
        )}
      {isDialogOpen && (
        <MessageDialog
          isSent={sent}
          onClose={handleCloseDialog}
          onEdit={handleEditMessage}
          onDelete={handleDelete}
          onPinMessage={onPinMessage}
          onForward={() => console.log('Forward message')}
          onReply={handleReply}
          message={{ msg: msg?.content || '', time, sent }}
          onReact={handleReact}
          messageType={messageType} 
        />
      )}
       {isReactionDialogOpen && (
        <ReactionDialog
          onClose={handleCloseReactionDialog}
          onSelectReaction={handleReactionSelect}
           
        />
      )}
        {isReactionManagementDialogOpen && (
          <ReactionManagementDialog
            reaction={selectedReaction}
            onRemoveReaction={handleRemoveReaction}
            onClose={handleCloseReactionManagementDialog}
            />
      )}
      <StatusTicks status={status} />
    </div>
  );
}

export default Message;





















