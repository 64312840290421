import React, { useState } from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';

const Media = ({ images = [], links = [], onBack }) => {
  const [activeTab, setActiveTab] = useState('media');

  const tabs = ['media', 'links', 'docs', 'products'];

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="flex flex-col h-full p-4 bg-[#202d33] text-white">
      <button 
        className="text-white mb-4 self-start text-xl"
        onClick={onBack}
      >
        <AiOutlineArrowLeft />
      </button>
      <h2 className="text-xl font-semibold mb-2">Media</h2>
      <div className="border-b border-gray-700 mb-4">
        <div className="flex space-x-4">
          {tabs.map(tab => (
            <span
              key={tab}
              className={`py-2 px-4 cursor-pointer ${
                activeTab === tab ? 'text-blue-400 border-b-2 border-blue-400' : 'text-gray-400'
              }`}
              onClick={() => handleTabClick(tab)}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </span>
          ))}
        </div>
      </div>
      <div className="flex-1 overflow-y-auto">
        {activeTab === 'media' && (
          <div className="space-y-2">
            {images.map(image => (
              <div key={image.id} className="flex items-center">
                <img 
                  src={image.url} 
                  alt={image.title} 
                  className="w-16 h-16 object-cover mr-2"
                />
                <span className="text-blue-400">{image.title}</span>
              </div>
            ))}
          </div>
        )}
        {activeTab === 'links' && (
          <div className="space-y-2">
            {links.map(link => (
              <a key={link.id} href={link.url} className="flex items-center" target="_blank" rel="noopener noreferrer">
                <span className="text-blue-400">{link.title}</span>
              </a>
            ))}
          </div>
        )}
        {activeTab === 'docs' && (
          <div className="space-y-2">
            {/* Render documents here */}
            <div className="flex items-center">
              <span className="text-blue-400">Example Document 1</span>
            </div>
          </div>
        )}
        {activeTab === 'products' && (
          <div className="space-y-2">
            {/* Render products here */}
            <div className="flex items-center">
              <span className="text-blue-400">Example Product 1</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Media;
