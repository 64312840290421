import React, { useEffect, useRef, useState } from 'react';
import { HiDotsVertical } from 'react-icons/hi';
import { MdSearch } from 'react-icons/md';
import { IoVideocam, IoVideocamOff } from 'react-icons/io5';
import Button from './common/Button';
import Dropdown from './DropDown';
import { MdAddCall } from "react-icons/md";
import SearchPopup from './SearchPopup';
import CallingPopup from './CallingPopup';

const ChatHeader = ({ user, onProfileClick, setShowSearchPopup ,onClearChat,isBlocked,onBlockContact,onUnblockContact}) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [showCallingPopup, setShowCallingPopup] = useState(false);
    const [isVideoCall, setIsVideoCall] = useState(false);
    const [mediaElement, setMediaElement] = useState(null);
    const dropdownRef = useRef(null);

    const handleDropdownToggle = () => {
        setShowDropdown(prev => !prev);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleVideoCall = () => {
        setShowCallingPopup(true);
        setIsVideoCall(true);
        // Access user's camera
        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
            .then((stream) => {
                // Mute all audio tracks initially
                stream.getAudioTracks().forEach(track => track.enabled = false);

                const video = document.createElement('video');
                video.srcObject = stream;
                video.play();
                document.body.appendChild(video);
                video.style.position = 'fixed';
                video.style.bottom = '20px';
                video.style.right = '20px';
                video.style.width = '200px';
                video.style.height = '150px';
                video.style.zIndex = '100';
                setMediaElement(video);
            })
            .catch((error) => {
                console.error('Error accessing camera:', error);
            });
    };

    const handleCall = () => {
        setShowCallingPopup(true);
        setIsVideoCall(false);
        // Access user's microphone for voice call
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                // Mute all audio tracks initially
                stream.getAudioTracks().forEach(track => track.enabled = false);

                const audio = document.createElement('audio');
                audio.srcObject = stream;
                audio.play();
                setMediaElement(audio);
                // Stop any video tracks if present
                const videoTracks = stream.getVideoTracks();
                videoTracks.forEach(track => track.stop());
            })
            .catch((error) => {
                console.error('Error accessing microphone:', error);
            });
    };

    const handleEndCall = () => {
        if (mediaElement) {
            const stream = mediaElement.srcObject;
            if (stream) {
                const tracks = stream.getTracks();
                tracks.forEach(track => track.stop());
            }
            mediaElement.remove();
        }
        setShowCallingPopup(false);
        setMediaElement(null);
    };

    const toggleVideo = () => {
        if (mediaElement && mediaElement.srcObject) {
            const videoTracks = mediaElement.srcObject.getVideoTracks();
            if (videoTracks.length > 0) {
                const videoTrack = videoTracks[0];
                videoTrack.enabled = !videoTrack.enabled;
                setIsVideoCall(videoTrack.enabled);
            } else {
                navigator.mediaDevices.getUserMedia({ video: true })
                    .then((stream) => {
                        const videoTrack = stream.getVideoTracks()[0];
                        mediaElement.srcObject.addTrack(videoTrack);
                        videoTrack.enabled = true;
                        setIsVideoCall(true);

                        // Create and style the video element
                        const video = document.createElement('video');
                        video.srcObject = stream;
                        video.play();
                        document.body.appendChild(video);
                        video.style.position = 'fixed';
                        video.style.bottom = '20px';
                        video.style.right = '20px';
                        video.style.width = '200px';
                        video.style.height = '150px';
                        video.style.zIndex = '100';
                        setMediaElement(video);
                    })
                    .catch((error) => {
                        console.error('Error accessing camera:', error);
                    });
            }
        }
    };

    return (
        <div className="relative">
            <div className="flex justify-between bg-[#202d33] h-[60px] p-3">
                <div className="flex items-center cursor-pointer" onClick={onProfileClick}>
                    <img
                        src={user.profilePicture}
                        alt="profile_picture"
                        className="rounded-full w-[45px] h-[45px] mr-5"
                    />
                    <div className="flex flex-col">
                        <h1 className="text-white font-medium">{user.name}</h1>
                        <p className="text-[#8796a1] text-xs">{user.status}</p>
                    </div>
                </div>
                <div className="flex items-center space-x-2"> 
                    <Button icon={<MdAddCall />} onClick={handleCall} />
                    <Button icon={<IoVideocam />} onClick={handleVideoCall} />
                    <Button icon={<MdSearch />} onClick={() => setShowSearchPopup(true)} />
                    <Button icon={<HiDotsVertical />} onClick={handleDropdownToggle} />
                    {showDropdown && (
                        <div className="absolute top-[60px] right-0 z-50" ref={dropdownRef}>
                            <Dropdown  
                            onClearChat={onClearChat}
                            onBlockContact={onBlockContact}
                            onUnblockContact={ onUnblockContact}
                            isBlocked={isBlocked}
                            
                            
                            />
                        </div>
                    )}
                </div>
            </div>
            {showCallingPopup && <CallingPopup onEndCall={handleEndCall} isVideoCall={isVideoCall} mediaElement={mediaElement} toggleVideo={toggleVideo} />}
        </div>
    );
};

export default ChatHeader;
