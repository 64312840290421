import React from 'react';

function ReplyManager({ replyingTo, onCancelReply }) {
  if (!replyingTo) return null;

  return (
    <div className="bg-[#2c3943] p-2 rounded-t-lg text-white text-sm">
      <div className="flex items-center">
        <span className="font-semibold">Replying to:</span>
        <span className="ml-2">{replyingTo.msg}</span>
        {/* <button className="ml-auto text-red-500" onClick={onCancelReply}>X</button> */}
      </div>
    </div>
  );
}

export default ReplyManager;
