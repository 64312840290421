import React, { useState } from "react";
import { MdPeopleAlt } from "react-icons/md";
import { GoCheckCircleFill } from "react-icons/go";
import Drawer from "react-modern-drawer";
import ContactsList from "./ContactList";

const NewChat = () => {
  const [search, setSearch] = useState("");
  const [selectedChat, setSelectedChat] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [isGroupNameInputVisible, setIsGroupNameInputVisible] = useState(false);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleChatClick = (contact) => {
    console.log(contact);
    setSelectedChat(contact);
  };

  const handleCreateGroup = () => {
    setIsDrawerOpen(true);
  };

  const handleSelectContact = (contact) => {
    setSelectedContacts((prevSelected) =>
      prevSelected.includes(contact)
        ? prevSelected.filter((c) => c !== contact)
        : [...prevSelected, contact]
    );
  };

  const handleGroupNameChange = (e) => {
    setGroupName(e.target.value);
  };

  const handleGroupCreation = () => {
    if (groupName.trim() !== "") {
      console.log("Group created:", { groupName, contacts: selectedContacts });
      setIsGroupNameInputVisible(false);
      setSelectedContacts([]);
      setGroupName("");
      setIsDrawerOpen(false);
    } else {
      alert("Group name cannot be empty.");
    }
  };

  return (
    <div className="flex flex-col overflow-y-scroll cursor-pointer h-100">
      <h1 className="text-white text-xl p-2 font-semibold">New chat</h1>
      <div className="flex justify-between items-center h-[60px] px-2 -mt-0">
        <input
          type="text"
          placeholder="Search contact"
          className="rounded-lg bg-[#202d33] text-[#8796a1] text-sm font-light outline-none mb-3 px-4 py-2 w-screen h-[35px] placeholder:text-[#8796a1] placeholder:text-sm placeholder:font-light"
          value={search}
          onChange={handleSearchChange}
        />
      </div>
      <button onClick={handleCreateGroup}>
        <div className="flex justify-start items-center px-3 py-3 hover:bg-[#3c454c]">
          <div className="text-[#8796a1] text-3xl p-2 rounded-full bg-[#3c454c]">
            <MdPeopleAlt />
          </div>
          <h1 className="text-white text-l p-2 font-light">New group</h1>
        </div>
      </button>
      <ContactsList
        search={search}
        handleChatClick={handleChatClick}
        selectedChat={selectedChat}
      />
      <Drawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        direction="left"
        className="drawer"
        style={{
          width: "400px",
          backgroundColor: "#111a21",
          backgroundColor: "#202d33",
        }}
      >
        <div className="bg-[#111a21] w-full h-full p-4 relative">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-white text-lg">Select Contacts</h2>
            <button
              className="text-white text-lg"
              onClick={() => {
                setIsDrawerOpen(false);
                setSelectedContacts([]);
              }}
            >
              &times;
            </button>
          </div>
          <ContactsList
            search={search}
            handleChatClick={handleChatClick}
            selectedChat={selectedChat}
            isGroupMode={true}
            handleSelectContact={handleSelectContact}
            selectedContacts={selectedContacts}
          />
          {selectedContacts.length > 0 && (
            <>
              <button
                className="absolute bottom-5 right-5 text-emerald-500 text-5xl rounded-full"
                onClick={() => setIsGroupNameInputVisible(true)}
              >
                <GoCheckCircleFill />
              </button>

              {isGroupNameInputVisible && (
                <div className="absolute bottom-20 right-6 bg-[#202d33] p-4 rounded-lg shadow-lg w-[350px]">
                  <input
                    type="text"
                    placeholder="Enter group name"
                    className="w-full p-2 rounded-lg bg-[#111a21] text-white outline-none"
                    value={groupName}
                    onChange={handleGroupNameChange}
                  />
                  <button
                    className="mt-2 bg-emerald-500 w-full py-2 rounded-lg text-white"
                    onClick={handleGroupCreation}
                    disabled={groupName.trim() === ""} // Disable button
                  >
                    Create Group
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default NewChat;
