import React from "react";
import { GoArrowLeft } from "react-icons/go";
import { MdOutlineSecurity } from "react-icons/md";
import { FaFileLines } from "react-icons/fa6";

const Account = ({ onBack }) => {
  return (
    <div className="min-h-screen bg-[#111a21] text-gray-300 p-4">
      <div
        className="flex justify-start items-center p-3 -ml-3"
        onClick={onBack}
      >
        <GoArrowLeft className="mr-4 text-lg cursor-pointer hover:bg-gray-700 rounded-full w-6 h-6" />
        <span className="text-lg">Account</span>
      </div>
      <div className="-ml-2 font-light">
        <div className="flex items-center p-3 rounded cursor-pointer hover:bg-gray-700">
          <MdOutlineSecurity className="mr-4 text-xl" />
          <span className="text-lg">Security notifications</span>
        </div>
        <div className="flex items-center p-3 rounded cursor-pointer hover:bg-gray-700">
          <FaFileLines className="mr-4 text-xl" />
          <span className="text-lg">Request account info</span>
        </div>
      </div>
    </div>
  );
};

export default Account;
