import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { TbGoGame } from "react-icons/tb";
import { PiClockCountdownFill } from "react-icons/pi";
function LoadingScreen({ progress }) {
  return (
    <div className="flex flex-col justify-center items-center bg-[#111a21] w-screen h-screen">
      <span className="text-[#3d464a] text-6xl my-12">
        <TbGoGame />
      </span>
      <div className="flex flex-col justify-evenly items-center h-[150px]">
        <div className="relative w-[320px]">
          <ProgressBar
            variant="success"
            now={progress}
            className="bg-[#243138] rounded-lg h-[3px]"
          />
          <div
            className="absolute top-0 left-0 right-0 flex justify-center items-center h-full"
            style={{ marginTop: '-0.5rem' }} 
          >
            <span className="text-xs text-blue-100">{progress}%</span>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <h1 className="text-[#c1c6c9] text-lg font-medium">ESupport App</h1>
          <div className="flex items-center text-[#687782]">
            <span className="text-sm mr-3">
              <PiClockCountdownFill />
            </span>
            <p>End-to-end encrypted</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoadingScreen;
