import React, { useState,useEffect, useRef } from 'react';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';

function MessageDialog({ onClose,messageType, onEdit, onDelete, onReact, onPin, onForward, onReply, isSent, onPinMessage,replyTo }) {
  const dialogRef = useRef(null);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        onClose(); 
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const handleDeleteClick = () => {
    setIsDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirm = (option) => {
    onDelete(option); // Pass the option ('me', 'everyone') to the parent
    setIsDeleteConfirmationOpen(false);
    onClose(); // Close the main dialog
  };

  const handleDeleteCancel = () => {
    setIsDeleteConfirmationOpen(false);
  };
  const handlePin = () => {
    onPinMessage();
    onClose();
  };

const handleReply=(message)=>{
onReply(message);
};

const handleReact = () => {
  onReact();
  onClose(); // Close the MessageDialog
};


  return (

    <div
      ref={dialogRef}
      className="absolute z-50 top-0 right-0 mt-2 mr-2 border rounded-md shadow-lg"
      style={{ backgroundColor: '#1c4f3f' }}
    > 
      {isSent &&  messageType === 'text' &&(
        <button onClick={onEdit} className="block px-4 py-2 text-white hover:bg-[#14543e] w-full text-left">Edit</button>
      )}
      <button onClick={handleDeleteClick} className="block px-4 py-2 text-white hover:bg-[#14543e] w-full text-left">Delete</button>
      <button onClick={handleReact} className="block px-4 py-2 text-white hover:bg-[#14543e] w-full text-left">React</button>
      <button onClick={handlePin} className="block px-4 py-2 text-white hover:bg-[#14543e] w-full text-left">Pin</button>
      <button onClick={onForward} className="block px-4 py-2 text-white hover:bg-[#14543e] w-full text-left">Forward</button>
      <button onClick={handleReply} className="block px-4 py-2 text-white hover:bg-[#14543e] w-full text-left">Reply</button>
      {isDeleteConfirmationOpen && (
        <DeleteConfirmationDialog 
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          isSent={isSent}
        />
      )}

    </div>
  );
}

export default MessageDialog;
