import React, { useState, useRef, useEffect } from 'react';
import Message from './MessageBuble';
import ChatHeader from './ChatHeader';
import { messagesData } from '../data/whatsapp';
import ChatInput from './ChatInput';
import { getTime } from '../logic/Time';
import SearchPopup from './SearchPopup';
import AttachmentDialog from './AttachmentDialog';
import Contacts from './ContactShareDialog';
import PinnedMessageDialog from './PinnedMessageDialog';
import { v4 as uuidv4 } from 'uuid';
import ConfirmationDialog from './ConfirmationDialog';

function ChatDetails({ onProfileClick, user }) {
  const [messages, setMessages] = useState(messagesData);
  const [typing, setTyping] = useState(false);
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false);
  const [showProfilePicDialog, setShowProfilePicDialog] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [showContactsList, setShowContactsList] = useState(false); 
  const [selectedContacts, setSelectedContacts] = useState([]); 
  const inputRef = useRef(null);  
  const bottomRef = useRef(null);
  const chatDetailsRef = useRef(null);
  const [pinnedMessages, setPinnedMessages] = useState([])
  const [replyingTo, setReplyingTo] = useState(null);
  const [currentPinnedIndex, setCurrentPinnedIndex] = useState(0);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [actionType, setActionType] = useState(null); 
  const [isBlocked, setIsBlocked] = useState(false); 

  const handleClearChat = () => {
    console.log('Clear chat button clicked');
    setShowConfirmationDialog(true);
    setActionType('clearChat');
  };
  const confirmClearChat=()=>{
    setMessages([]);
    setShowConfirmationDialog(false);
  }

  const handleBlockContact = () => {
    setShowConfirmationDialog(true);
    setActionType('block');
  };
  const handleUnblockContact = () => {
    setShowConfirmationDialog(true);
    setActionType('unblock');
  };
  const confirmAction = () => {
    if (actionType === 'block') {
      setIsBlocked(true);
      setShowConfirmationDialog(false);
    } else if (actionType === 'unblock') {
      setIsBlocked(false);
      setShowConfirmationDialog(false);
    } else if (actionType === 'clearChat') {
      confirmClearChat();
    }
  };
  const cancelAction = () => {
    setShowConfirmationDialog(false);
  };
  const cancelClearChat=()=>{
    setShowConfirmationDialog(false);
  }

  const addMessage = (msg) => {
    setMessages(prevMessages => [...prevMessages, msg]); 
  };
  const updateMessage = (index, newMessage) => {
    setMessages(prevMessages => {
      const updatedMessages = [...prevMessages];
      updatedMessages[index] = { ...updatedMessages[index], msg: newMessage };
      return updatedMessages;
    });
  };
  const handlePinMessage = (index) => {
    const messageToPin = messages[index];
    setPinnedMessages(prevPinnedMessages => [...prevPinnedMessages, messageToPin]);
  };

  const handleUnpinMessage = (index) => {
    setPinnedMessages(prevPinnedMessages => prevPinnedMessages.filter((_, i) => i !== index));
  };

  const handleReply = (msg) => {
    console.log('handle reply  message:', msg);
    setReplyingTo(msg); 
  };

  
  const handleInputSubmit = (message) => {
    if (message && message.content && message.content.length > 0) {
      const newMessage = {
        id: uuidv4(),
        msg: message.content, 
        time: getTime(),
        sent: true,
        replyTo: replyingTo ? replyingTo.id : null,
      };
  
      setReplyingTo(null); 
      console.log("Adding Message:", newMessage);
      addMessage(newMessage);
      setTyping(false); 
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  };
  
  const handleVoiceMessageSubmit = (audioBlob) => {
    const audioURL = URL.createObjectURL(audioBlob);
    const newVoiceMessage = {
      id: uuidv4(),
      audio: audioURL,
      time: getTime(),
      sent: true,
    };
    addMessage(newVoiceMessage);
  };

  const handleEmojiSelect = (emoji) => {
    if (inputRef.current) {
      inputRef.current.value += emoji;
      inputRef.current.focus();
    }
  };

  const handleFileSelect = (files) => {
    files.forEach(file => {
      const isImage = file.type.startsWith('image/');
      addMessage({
        msg: isImage ? null : file.name, 
        time: getTime(),
        sent: true,
        file: file,
        isImage: isImage,
      });
    });
  };

  const handleSendPoll = (poll) => {
    addMessage({
      poll: poll,
      time: getTime(),
      sent: true,
    });
  };

  const handleSendContacts = (contacts) => {
    contacts.forEach(contact => {
      addMessage({

        id: uuidv4(),
        msg: `Shared contact: ${contact}`,
        time: getTime(),
        sent: true,
      });
    });
    setSelectedContacts(contacts); 
  };

  const handleVote = (pollIndex, optionIndex) => {
    console.log(`Poll ${pollIndex} option ${optionIndex} selected`);
  };

  const toggleAttachmentDialog = () => {
    setShowAttachmentDialog(prev => !prev);
  };

  const handleProfilePicClick = (profileImage) => {
    setProfilePic(profileImage);
    setShowProfilePicDialog(true);
  };

  const handleEditMessage = (index, newMessage) => {
    updateMessage(index, newMessage);
  };

  const toggleContactsList = () => {
    setShowContactsList(prev => !prev);
  };
  const handleDeleteMessage = (index, option) => {
    setMessages(prevMessages => {
      const updatedMessages = [...prevMessages];
      if (option === 'everyone') {
        updatedMessages[index] = {
          ...updatedMessages[index],
          msg: 'This message was deleted',
          isDeleted: true, 
          file: null, 
          audio: null, 
          img: null, 
        };
      } else {
        updatedMessages.splice(index, 1);
      }
      return updatedMessages;
    });
  };
  const handleNextPinnedMessage = () => {
    setCurrentPinnedIndex((prevIndex) => (prevIndex + 1) % pinnedMessages.length);
  };

  const handlePrevPinnedMessage = () => {
    setCurrentPinnedIndex((prevIndex) => (prevIndex - 1 + pinnedMessages.length) % pinnedMessages.length);
  };


  useEffect(() => {
    bottomRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  }, [messages]);

  useEffect(() => {
    const listener = (e) => {
      if (e.code === 'Enter' && !e.shiftKey) {  
        e.preventDefault();  
        if (inputRef.current) {
          handleInputSubmit(inputRef.current.value);
        }
      }
    };

    document.addEventListener('keydown', listener);
    return () => document.removeEventListener('keydown', listener);
  }, []);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (chatDetailsRef.current && !chatDetailsRef.current.contains(e.target)) {
        setShowProfilePicDialog(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="flex flex-col h-screen relative" ref={chatDetailsRef}>
      <ChatHeader 
      user={user} 
      onProfileClick={onProfileClick} 
      setShowSearchPopup={setShowSearchPopup} 
      onClearChat={handleClearChat}
      onBlockContact={handleBlockContact}
      onUnblockContact={handleUnblockContact}
      isBlocked={isBlocked}
      />


      {showSearchPopup ? (
        <SearchPopup onClose={() => setShowSearchPopup(false)} messages={messages} />
      ) : (
        <>
          <div
            className={`bg-[#0a131a] bg-[url('assets/images/bg.webp')] bg-contain overflow-y-scroll h-100 scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800 ${showProfilePicDialog ? 'blur-sm' : ''}`}
            style={{ padding: '12px 7%' }}
          >
               {pinnedMessages.length > 0 && pinnedMessages.map((msg, index) => (
             <PinnedMessageDialog
             key={index}
             message={pinnedMessages[currentPinnedIndex].msg}
             onUnpin={() => handleUnpinMessage(currentPinnedIndex)}
             onNext={handleNextPinnedMessage}
             onPrev={handlePrevPinnedMessage}
             isFirst={currentPinnedIndex === 0}
             isLast={currentPinnedIndex === pinnedMessages.length - 1}
           />
            ))}

            {messages.map((msg, index) => (
             (
                <Message
                  key={msg.id}
                  msg={msg.msg}
                  poll={msg.poll}
                  time={msg.time}
                  sent={msg.sent}
                  file={msg.file}
                  img={msg.img}
                  audio={msg.audio}
                  profileImage={msg.profileImage}
                  onProfilePicClick={handleProfilePicClick}
                  onEditMessage={(newMessage) => handleEditMessage(index, newMessage)}
                  onDeleteMessage={(option) => handleDeleteMessage(index, option)}
                  onPinMessage={() => handlePinMessage(index)}
                  onReplyMessage={() => handleReply(msg)}
                  replyingTo={msg.replyTo ? messages.find(msg => msg.id === msg.replyTo) : null}                  
                  onVote={handleVote}
                />
              )
            ))}
            <div ref={bottomRef}></div>
          </div>

          {showAttachmentDialog && (
            <AttachmentDialog
              onFileSelect={handleFileSelect}
              onSendPoll={handleSendPoll}
              onSendContacts={handleSendContacts} 
              onClose={toggleAttachmentDialog}
            />
          )}

          {showContactsList && (
            <Contacts
              onSelectContacts={handleSendContacts} 
              onClose={toggleContactsList}
            />
          )}
         {!isBlocked ? (
            <ChatInput
              onEmojiClick={() => {}}
              onEmojiSelect={handleEmojiSelect}
              onAttachmentToggle={toggleAttachmentDialog}
              onInputSubmit={handleInputSubmit}
              onVoiceMessageSubmit={handleVoiceMessageSubmit}
              typing={setTyping}
              inputRef={inputRef}
              replyingTo={replyingTo}
            />
          ) : (
            <div className="p-4 text-center text-gray-500">This contact is blocked by you</div>
          )}
        </>
      )}
        {showProfilePicDialog && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-70 z-50">
          <img
            src={profilePic}
            alt="Profile"
            className="max-w-[80%] max-h-[80%] rounded-lg"
          />
        </div>
      )}
 {showConfirmationDialog && (
      <div className="absolute inset-0 flex items-center justify-center z-50">
      <ConfirmationDialog
          title={actionType === 'clearChat' ? 'Clear Chat' : (actionType === 'block' ? 'Block Contact' : 'Unblock Contact')}
          message={actionType === 'clearChat' ? 'Are you sure you want to clear the chat?' : (actionType === 'block' ? 'Are you sure you want to block this contact?' : 'Are you sure you want to unblock this contact?')}
          onConfirm={confirmAction}
          onCancel={cancelAction}
        />
    </div>
 )}
    </div>
  );
}

export default ChatDetails;

  
