import React from "react";
import { GoArrowLeft } from "react-icons/go";
import { IoIosHelpCircle } from "react-icons/io";
import { MdPeople } from "react-icons/md";
import { FaFileLines } from "react-icons/fa6";

const Help = ({ onBack }) => {
  const helpMenu = [
    {
      icon: <IoIosHelpCircle />,
      label: "Help Center",
      className: "text-gray-300 text-2xl",
    },
    {
      icon: <MdPeople />,
      label: "Contact us",
      className: "text-gray-300 text-2xl",
    },
    {
      icon: <FaFileLines />,
      label: "Terms and Privacy Policy",
      className: "text-gray-300 text-2xl",
    },
  ];
  return (
    <div className="min-h-screen bg-[#111a21] text-gray-300 p-4">
      <div
        className="flex justify-start items-center p-3 -ml-3 cursor-pointer"
        onClick={onBack}
      >
        <GoArrowLeft className="mr-4 text-lg hover:bg-gray-700 rounded-full w-6 h-6" />
        <span className="text-lg">Help</span>
      </div>
      {helpMenu.map((item, index) => (
        <div
          key={index}
          className={`flex items-center w-full p-3 hover:bg-[#202d33] cursor-pointer border-b border-gray-700 space-y-3 ${
            item.className ? item.className : ""
          }`}
        >
          {item.icon}
          <p
            className={`text-base ml-4 ${
              item.textClass ? item.textClass : "text-gray-300"
            }`}
          >
            {item.label}
          </p>
        </div>
      ))}
    </div>
  );
};

export default Help;
