import React, { useState } from "react";
import { AiOutlineClose, AiOutlineArrowLeft } from 'react-icons/ai'; 
import { AiOutlineBlock, AiOutlineFlag, AiOutlineDelete } from 'react-icons/ai'; 
import { MdNotificationsOff, MdOutlineMessage, MdOutlineLock } from 'react-icons/md'; 
import Media from './Media'; 

const UserDetails = ({ user, mediaImages, onClose }) => {
  const [showMedia, setShowMedia] = useState(false);

  const handleBlockContact = () => {
    console.log("Block Contact clicked");
  };

  const handleReportContact = () => {
    console.log("Report Contact clicked");
  };

  const handleDeleteChat = () => {
    console.log("Delete Chat clicked");
  };

  const handleMuteNotifications = () => {
    console.log("Mute Notifications clicked");
  };

  const handleDisappearingMessages = () => {
    console.log("Disappearing Messages clicked");
  };

  const handleEncryption = () => {
    console.log("Encryption clicked");
  };

  const handleShowMedia = () => {
    setShowMedia(true);
  };

  const handleCloseMedia = () => {
    setShowMedia(false);
  };

  return (
    <div className="flex flex-col h-full p-4 bg-[#202d33] text-white">
      {showMedia ? (
        <>
          <Media images={mediaImages} onBack={handleCloseMedia} />
        </>
      ) : (
        <>
          <button 
            className="text-white mb-4 self-start text-xl"
            onClick={onClose}
          >
            <AiOutlineClose />
          </button>
          <div className="flex-1 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800 space-y-4">
            <div className="bg-[#1c2730] p-4 rounded-lg">
              <div className="flex flex-col items-center mb-4">
                <img
                  src={user.profilePicture}
                  alt="profile_picture"
                  className="rounded-full w-[100px] h-[100px] mb-4"
                />
                <h1 className="text-2xl font-medium mb-2">{user.name}</h1>
                <p className="text-sm text-gray-400 mb-2">{user.mobileNumber}</p>
              </div>
            </div>
            <div className="bg-[#1c2730] p-4 rounded-lg">
              <h2 className="text-xl font-semibold mb-2">About</h2>
              <p className="text-sm text-gray-300">{user.about}</p> 
            </div>
            <div className="bg-[#1c2730] p-4 rounded-lg">
              <h2 className="text-xl font-semibold mb-2 cursor-pointer" onClick={handleShowMedia}>Media</h2>
            </div>
            <div className="bg-[#1c2730] p-4 rounded-lg">
              <h2 className="text-xl font-semibold mb-2">Options</h2>
              <div className="flex flex-col space-y-2">
                <div className="flex items-center text-red-500 cursor-pointer" onClick={handleBlockContact}>
                  <AiOutlineBlock className="mr-2" />
                  <span>Block Contact</span>
                </div>
                <div className="flex items-center text-red-500 cursor-pointer" onClick={handleReportContact}>
                  <AiOutlineFlag className="mr-2" />
                  <span>Report Contact</span>
                </div>
                <div className="flex items-center text-red-500 cursor-pointer" onClick={handleDeleteChat}>
                  <AiOutlineDelete className="mr-2" />
                  <span>Delete Chat</span>
                </div>
              </div>
            </div>
            <div className="bg-[#1c2730] p-4 rounded-lg">
              <h2 className="text-xl font-semibold mb-2">More Options</h2>
              <div className="flex flex-col space-y-2">
                <div className="flex items-center text-red-500 cursor-pointer" onClick={handleMuteNotifications}>
                  <MdNotificationsOff className="mr-2" />
                  <span>Mute Notifications</span>
                </div>
                <div className="flex items-center text-red-500 cursor-pointer" onClick={handleDisappearingMessages}>
                  <MdOutlineMessage className="mr-2" />
                  <span>Disappearing Messages</span>
                </div>
                <div className="flex items-center text-red-500 cursor-pointer" onClick={handleEncryption}>
                  <MdOutlineLock className="mr-2" />
                  <span>Encryption</span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default UserDetails;
