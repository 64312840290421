import "bootstrap/dist/css/bootstrap.min.css";
import ChatWeb from "./pages/ChatWeb";

function App() {
  return (
    <ChatWeb />
  );
}

export default App;
