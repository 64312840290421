import React from "react";

const DropDownMenu = ({ options, position, onClose }) => {
  return (
    <div
      className="absolute bg-[#202d33] text-white rounded-lg shadow-lg z-10 w-48"
      style={{
        top: position.top,
        left: position.left,
      }}
    >
      {options.map((option, index) => (
        <div
          key={index}
          className="px-4 py-2 hover:bg-[#3c454c] cursor-pointer"
          onClick={() => {
            option.onClick();
            onClose();
          }}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};

export default DropDownMenu;
