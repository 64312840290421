import React from "react";
import qrCodeImage from "../assets/images/qr.png";
import whatsappIcon from "../assets/images/chat1.png";
import additionalImage from "../assets/images/chat1.png";

function QRCodeLogin({ onContinue }) {
  return (
    
    <div className="w-screen h-screen flex flex-col relative">
      {/* Navigation */}
      <div className="nav flex items-center bg-green-300 p-4 z-10 relative">
        <img className="w-20 h-20 mr-4" src={whatsappIcon} alt="WhatsApp Icon" />
        <span className="text-4xl font-bold">Chat Web</span>
      </div>

      {/* QR Code and Other Content */}
      <div
        className="absolute inset-x-0 top-1/4 flex flex-col items-center"
        style={{ zIndex: 5 }}
      >
        <div className="flex flex-col bg-white p-20 rounded-lg shadow-lg relative" style={{ width: '80%', maxWidth: '1200px' }}>
          {/* First Row */}
          <div className="flex flex-row mb-10">
            <div>
              <p className="mb-4 text-2xl text-gray-700">Use Chat App in Your Computer</p>
              <div className="flex flex-col flex-1 mr-4">
                <p className="mb-4">1. First Line of Text</p>
                <p className="mb-4">2. Second Line of Text</p>
                <p className="mb-4">3. Third Line of Text</p>
                <p className="mb-4">4. Fourth Line of Text</p>
              </div>
            </div>

            <div className="flex flex-1 justify-center items-center">
              <img className="w-60 h-60" src={qrCodeImage} alt="QR Code" />
            </div>
          </div>

          {/* Second Row */}
          <div>
            <p className="mb-4 text-xl text-green-500">Link With Your Phone Number</p>
            <div className="flex justify-center mb-10">
              <img className="w-48 h-48" src={additionalImage} alt="Additional Content" />
            </div>
          </div>

          {/* Third Row */}
          <div className="flex justify-center">
            <div className="flex justify-center">
              <p
                onClick={onContinue}
                className="text-green-500 cursor-pointer hover:underline"
              >
                Continue
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QRCodeLogin;
