import React, { useState, useRef,useEffect } from 'react';
import { AiOutlinePaperClip } from 'react-icons/ai';
import { BiHappy } from 'react-icons/bi';
import { BsFillMicFill } from 'react-icons/bs';
import { MdSend } from 'react-icons/md';
import Button from './common/Button';
import { FaPaperclip, FaMicrophone, FaSmile, FaTimes } from 'react-icons/fa';
import EmojiPicker from './Emoji';
import ReplyManager from './ReplyManager';

function ChatInput({ onEmojiClick, onEmojiSelect, onAttachmentToggle, 
  onInputSubmit, onVoiceMessageSubmit, typing, inputRef, replyingTo, onSubmit }) {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [chunks, setChunks] = useState([]);

  const handleEmojiClick = () => {
    setShowEmojiPicker(!showEmojiPicker);
    onEmojiClick();
  };

  const handleEmojiSelect = (emoji) => {
    if (inputRef.current) {
      inputRef.current.value += emoji;
      inputRef.current.focus();
      onEmojiSelect(emoji);
    }
  };

  const toggleAttachmentDialog = () => {
    onAttachmentToggle();
  };

  const handleInputChange = () => {
    if (inputRef.current) {
      typing(inputRef.current.value.length > 0);
    }
  };
  const handleTextInputSubmit = () => {
    if (inputRef.current && inputRef.current.value) {
      const messageContent = inputRef.current.value;
      const referenceMessage = replyingTo ? `Replying to: ${replyingTo.msg}\n` : '';
  
      const message = {
        content: referenceMessage + messageContent,
        replyTo: replyingTo ? { id: replyingTo.id, msg: replyingTo.msg } : null,
      };
  
      console.log("Handling Input Submit:", message);
      onInputSubmit(message);
      inputRef.current.value = ''; 
    }
  };
  
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); 
      handleTextInputSubmit(); 
    }
  };
  const startRecording = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          const recorder = new MediaRecorder(stream);
          setMediaRecorder(recorder);
          recorder.ondataavailable = (e) => {
            if (e.data.size > 0) {
              const audioBlob = new Blob([e.data], { type: 'audio/wav' });
              onVoiceMessageSubmit(audioBlob);
            }
          };
          recorder.start();
          setIsRecording(true);
        })
        .catch(err => console.error('Error accessing audio devices', err));
    }
  };

  useEffect(() => {
    console.log('Replying To in ChatInput:', replyingTo);
  }, [replyingTo]);
  

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);
    }
  };

  return (
    <div className="flex flex-col bg-[#202d33] w-full" >
      {replyingTo && (
        <ReplyManager
          replyingTo={replyingTo}
          onCancelReply={() => onSubmit(null)} 
        />
      )}
     
      <div className="flex items-center w-full h-[70px] p-2">
        <Button icon={<BiHappy />} onClick={handleEmojiClick} />
        <span className="mr-2">
          <Button icon={<AiOutlinePaperClip />} onClick={toggleAttachmentDialog} />
        </span>
        <input
        
          type="text"
          placeholder="Type a message"
          className="bg-[#2c3943] rounded-lg outline-none text-sm text-neutral-200 w-full h-full px-3 placeholder:text-sm placeholder:text-[#8796a1]"
          onChange={handleInputChange}
          ref={inputRef}
          onKeyDown={handleKeyDown}
          
        />
        <span className="ml-2">
          {isRecording ? (
            <Button icon={<MdSend />} onClick={stopRecording} />
          ) : (
            <Button icon={<BsFillMicFill />} onClick={startRecording} />
          )}
        </span>
       

         {/* button  is in here */}

        {showEmojiPicker && (
          <EmojiPicker onClose={handleEmojiClick} onEmojiSelect={handleEmojiSelect} />
        )}
      </div>
    </div>
  );
}

export default ChatInput;

///<span className="ml-2">
///<Button icon={<MdSend />} onClick={handleTextInputSubmit} /> {/* Use this button to trigger submission */}
//</span>