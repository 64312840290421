import React, { useState, useEffect, useRef } from "react";
import { FaAngleDown } from "react-icons/fa6";
import DropDownMenu from "./common/DropDownMenu";

const Chat = ({
  pp,
  contact,
  msg,
  time,
  unreadMsgs,
  active,
  onClick,
  context,
}) => {
  const isContactsList = context === "contactsList";

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
  });

  const dropdownRef = useRef(null);

  const handleDropdownClick = (event) => {
    event.stopPropagation();
    setDropdownPosition({ top: event.clientY, left: event.clientX });
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleArchiveChat = () => {
    console.log(`Archived ${contact}`);
  };

  const handleMuteNotification = () => {
    console.log(`Mute ${contact}`);
  };

  const handleDeleteChat = () => {
    console.log(`Delete ${contact}`);
  };

  const handlePinChat = () => {
    console.log(`Pin ${contact}`);
  };

  const handleMarkAsread = () => {
    console.log(`Mark as read ${contact}`);
  };

  const handleBlock = () => {
    console.log(`Block ${contact}`);
  };

  const dropdownOptions = [
    { label: "Archived chat", onClick: handleArchiveChat },
    {
      label: "Mute notification",
      onClick: handleMuteNotification,
    },
    { label: "Delete chat", onClick: handleDeleteChat },
    { label: "Pin chat", onClick: handlePinChat },
    { label: "Mark as read", onClick: handleMarkAsread },
    { label: "Block", onClick: handleBlock },
  ];

  return (
    <div
      className={`flex justify-between items-center cursor-pointer w-100 h-[85px] px-3 hover:bg-[#202d33]  ${
        active ? "bg-[#202d33]" : ""
      }`}
      onClick={onClick}
    >
      {/* profile picture */}
      <img
        src={pp}
        alt="profile-picture"
        className={`rounded-full w-[50px] mr-5 ${
          isContactsList ? "border border-gray-600" : ""
        }`}
      />

      {/* Information container */}
      <div
        className={`flex justify-between w-100 h-100 py-3 ${
          isContactsList ? "" : "border-t border-neutral-700"
        }`}
      >
        {/* Contact name and message */}
        <div className="flex flex-col justify-center text-white">
          {/* contact name */}
          <h1 className="font-medium mb-1">{contact}</h1>

          {/* message */}
          <p className={`text-sm ${!unreadMsgs ? "text-neutral-400" : ""}`}>
            {msg}
          </p>
        </div>

        {/* Time and number of messages */}
        <div className="flex flex-col justify-between items-end h-100 text-xs">
          {/* time */}
          <p className="text-emerald-500 min-w-[55px]">{time}</p>

          <div className="flex justify-between items-center px-1">
            {/* number of messages */}
            {unreadMsgs && (
              <div className="flex justify-center items-center bg-emerald-500 rounded-full w-[20px] h-[20px] mr-2">
                <p className="text-emerald-900">{unreadMsgs}</p>
              </div>
            )}

            {!isContactsList && (
              <div
                className={`flex justify-center items-center hover:bg-slate-600 hover:rounded-full w-4 h-4 text-neutral-400 `}
                ref={dropdownRef}
              >
                <button onClick={handleDropdownClick}>
                  <FaAngleDown />
                </button>
              </div>
            )}

            {dropdownVisible && (
              <DropDownMenu
                options={dropdownOptions}
                position={dropdownPosition}
                onClose={() => setDropdownVisible(false)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
