// import React from 'react';

// const DocumentPopup = ({ onClose, onFileSelect }) => {
//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       onFileSelect(file);
//     }
//     onClose();
//   };

//   return (
//     <div className="absolute top-0 left-0 w-full h-full bg-[#222f35] p-4 z-20 flex justify-center items-center">
//       <div className="bg-[#1c2730] text-white shadow-lg rounded-lg w-[300px] p-4">
//         <h2 className="text-xl font-semibold mb-4">Select Document</h2>
//         <input type="file" onChange={handleFileChange} accept=".pdf,.doc,.docx,.txt" className="w-full p-2 bg-gray-700 rounded" />
//         <button 
//           className="mt-4 bg-red-500 p-2 rounded-lg w-full text-white"
//           onClick={onClose}
//         >
//           Close
//         </button>
//       </div>
//     </div>
//   );
// };

// export default DocumentPopup;


import React from 'react';

const DocumentPopup = ({ onClose, onFileSelect }) => {
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      onFileSelect(files);
    }
    onClose();
  };

  return (
    <div className="absolute top-0 left-0 w-full h-full bg-[#222f35] p-4 z-20 flex justify-center items-center">
      <div className="bg-[#1c2730] text-white shadow-lg rounded-lg w-[300px] p-4">
        <h2 className="text-xl font-semibold mb-4">Select Documents</h2>
        <input 
          type="file" 
          onChange={handleFileChange} 
          accept=".pdf,.doc,.docx,.txt,.png,.jpg,.jpeg" 
          className="w-full p-2 bg-gray-700 rounded" 
          multiple 
        />
        <button 
          className="mt-4 bg-red-500 p-2 rounded-lg w-full text-white"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default DocumentPopup;

