import React, { useState, useEffect } from "react";
import { IoIosShareAlt } from "react-icons/io";
import { chatsData } from "../data/whatsapp";
import Chat from "./Chat";

const Contacts = ({ 
  search = '', 
  handleChatClick = () => {}, 
  selectedChat, 
  isGroupMode, 
  handleSelectContact, 
  selectedContacts, 
  onClose, 
  onShareContact = () => {} 
}) => {
  const sortedChatsData = chatsData
    .filter((chat) => chat.contact && /^[a-zA-Z\s]+$/.test(chat.contact))
    .sort((a, b) => a.contact.localeCompare(b.contact));

  const [chats, setChats] = useState(sortedChatsData);
  const [selectedContactList, setSelectedContactList] = useState([]);

  useEffect(() => {
    const filteredChats = sortedChatsData.filter((chat) =>
      chat.contact && chat.contact.toLowerCase().includes(search.toLowerCase())
    );
    setChats(filteredChats);
  }, [search]);

  const groupedChats = chats.reduce((acc, chat) => {
    const firstLetter = chat.contact.charAt(0).toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(chat);
    return acc;
  }, {});

  const handleContactSelect = (contact) => {
    setSelectedContactList((prev) => {
      if (prev.includes(contact)) {
        return prev.filter((c) => c !== contact);
      } else {
        return [...prev, contact];
      }
    });
  };

  const handleShareClick = () => {
    console.log('onShareContact:', onShareContact); 
    if (onShareContact) {
      onShareContact(selectedContactList);
    } else {
      console.error('onShareContact is not defined or not a function');
    }
    onClose();
  };

  const handleKeyDown = (e) => {
    const scrollContainer = document.getElementById("contacts-scroll-container");
    if (scrollContainer) {
      if (e.key === "ArrowDown") {
        scrollContainer.scrollBy(0, 30);
      } else if (e.key === "ArrowUp") {
        scrollContainer.scrollBy(0, -30);
      }
    }
  };

  return (
    <div 
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      onClick={(event) => { if (event.target === event.currentTarget) onClose(); }}
    >
      <div 
        className="bg-[#1c2730] text-white shadow-lg rounded-lg w-[300px] p-4"
        tabIndex="0"
        onKeyDown={handleKeyDown}
      >
        <h2 className="text-xl font-semibold mb-4">Share Contact</h2>
        <div 
          id="contacts-scroll-container"
          className="flex flex-col overflow-y-auto cursor-pointer h-[400px]" // Adjust the height as needed
        >
          <div className="flex flex-col space-y-2 mt-1">
            {Object.keys(groupedChats).map((letter) => (
              <div key={letter}>
                <div className="text-[#8796a1] text-sm font-light px-2 py-0 mb-0 bg-[#202d33]">
                  {letter}
                </div>
                {groupedChats[letter].map((chat) => (
                  <div key={chat.id} className="flex items-center">
                    <input
                      type="checkbox"
                      checked={selectedContactList.includes(chat.contact)}
                      onChange={() => handleContactSelect(chat.contact)}
                    />
                    <Chat
                      pp={chat.pp}
                      contact={chat.contact}
                      active={selectedChat === chat.contact}
                      onClick={() => handleChatClick(chat.contact)}
                      context="contactsList"
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div
          className="mt-4 flex items-center justify-center cursor-pointer"
          onClick={handleShareClick}
        >
          <IoIosShareAlt size={24} color="white" />
        </div>
      </div>
    </div>
  );
};

export default Contacts;
