import React from "react";
import { GoArrowLeft } from "react-icons/go";

const Notifications = ({ onBack }) => {
  // Sample data
  const notificationMenu = [
    {
      label: "Message notifications",
      description: "Show notifications for new messages",
    },
    {
      label: "Background sync",
      description:
        "Get faster performance by syncing messages in the background",
    },
    {
      label: "Sounds",
      description: "Play sounds for incoming messages",
    },
  ];

  return (
    <div className="min-h-screen bg-[#111a21] text-gray-300 p-4">
      <div
        className="flex justify-start items-center p-3 -ml-3 cursor-pointer"
        onClick={onBack}
      >
        <GoArrowLeft className="mr-4 text-lg hover:bg-gray-700 rounded-full w-6 h-6" />
        <span className="text-lg">Notifications</span>
      </div>
      <div className="mt-4 font-light">
        {notificationMenu.map((item, index) => (
          <div key={index} className="flex items-center justify-between p-3">
            <div className="flex flex-col">
              <span className="block text-base">{item.label}</span>
              <div className="flex justify-between  w-[340px]">
                <span className="block text-sm text-gray-500 w-[300px]">
                  {item.description}
                </span>
                <input
                  id={`checkbox-${index}`}
                  type="checkbox"
                  value=""
                  className="mt-1 w-4 h-4 text-green-600 bg-black border-gray-800 rounded-lg cursor-pointer focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 checked:bg-green-600"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Notifications;
