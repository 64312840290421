import React from "react";

const StatusList = () => {
  return (
    <div className="relative flex flex-col items-center justify-center h-screen w-full bg-[#111a21] overflow-y-scroll ">
      <h2 className="text-gray-500">Not available</h2>
    </div>
  );
};

export default StatusList;
