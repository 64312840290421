
import React, { useState } from 'react';
import { FaPoll } from 'react-icons/fa'; 
import StatusTicks from './StatusTicks'; 

const PollMessage = ({ poll, onVote, sent, id, status }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleRadioChange = (index) => {
    setSelectedOption(index);
    onVote(id, index); 
  };

  return (
    <div
      className={`relative flex flex-col items-start rounded-md w-[400px] p-4 mb-4 shadow-lg ${
        sent ? 'bg-[#005c4b] ml-auto' : 'bg-[#202d33] mr-auto'
      }`}
      style={{ wordWrap: 'break-word' }}
    >
      {/* Poll Icon */}
      <FaPoll
        className="absolute top-2 right-2 text-[#4f9abf] h-6 w-6"
        aria-label="Poll Icon"
      />

      <h3 className="text-lg font-semibold mb-3 text-white">{poll.question}</h3>
      <div className="space-y-2">
        {poll.options.map((option, index) => (
          <div
            key={index}
            className="flex items-center mb-2 p-2 border-b border-[#4f9abf]"
          >
            <input
              type="radio"
              name={`pollOption-${id}`}
              checked={selectedOption === index}
              onChange={() => handleRadioChange(index)}
              className="mr-3 h-4 w-4 accent-[#4f9abf]"
            />
            <span className="text-sm text-white" style={{ display: 'block', maxWidth: '250px' }}>{option}</span>
          </div>
        ))}
      </div>

      {/* Status Ticks */}
      <StatusTicks status={status} />
    </div>
  );
};

export default PollMessage;
