import {
  cs1,
  cs2,
  chat1,
  chat2,
  chat3,
  chat4,
  chat5,
  chat6,
  chat7,
  chat8,
  chat9,
  chat10,
  chat11,
  chat12,
  chat13,
} from "../assets/whatsapp";

export const chatsData = [
  {
    pp: cs1,
    contact: "Esupport",
    msg: "Hello, how can I help you?",
    time: "1:15 pm",
    unreadMsgs: null,
  },
  {
    pp: chat1,
    contact: "Tom",
    msg: "Coding Spot is fire! 🔥",
    time: "12:15 pm",
    unreadMsgs: 2,
  },
  {
    pp: chat2,
    contact: "Mr. Perera",
    msg: "Miss you, call you later!",
    time: "11:11 am",
    unreadMsgs: 4,
  },
  {
    pp: chat3,
    contact: "Dad",
    msg: "Hey dad, need you asap!!",
    time: "11:04 am",
    unreadMsgs: null,
  },
  {
    pp: chat4,
    contact: "+00 123 4567890",
    msg: "Who are you???",
    time: "10:58 am",
    unreadMsgs: null,
  },
  {
    pp: chat5,
    contact: "Sam",
    msg: "Help me with this bug!! 😭😭",
    time: "10:50 am",
    unreadMsgs: 23,
  },
  {
    pp: chat6,
    contact: "Mom",
    msg: "Call me son, love you. ❤️",
    time: "10:35 am",
    unreadMsgs: 2,
  },
  {
    pp: chat7,
    contact: "Johnny",
    msg: "hahhahhaha lol 😂😂😂",
    time: "10:18 am",
    unreadMsgs: null,
  },
  {
    pp: chat8,
    contact: "Boss",
    msg: "Meeting in 2 hours.",
    time: "10:02 am",
    unreadMsgs: null,
  },
  {
    pp: chat9,
    contact: "Sarah",
    msg: "The test was sooo hard 😓",
    time: "9:47 am",
    unreadMsgs: 3,
  },
  {
    pp: chat10,
    contact: "Doctor",
    msg: "Hey doc, waitng for the results",
    time: "9:41 am",
    unreadMsgs: null,
  },
  {
    pp: chat11,
    contact: "Alex",
    msg: "Wanna hang out? 🍺🍻",
    time: "yesterday",
    unreadMsgs: 3,
  },
  {
    pp: chat12,
    contact: "Little Brother",
    msg: "I love this videogame 🕹🎮",
    time: "yesterday",
    unreadMsgs: 6,
    status:"read",
    profileImage: chat1
  },
  {
    pp: chat13,
    contact: "Professor Elizabeth",
    msg: "Forgot to send the task 😭",
    time: "yesterday",
    unreadMsgs: null,
  },
  {
    pp: chat4,
    contact: "Test",
    msg: "Test chat ...",
    time: "yesterday",
    unreadMsgs: null,
    profileImage: chat1
  },
  {
    pp: chat4,
    contact: "Test2",
    msg: "Test chat ...",
    time: "yesterday",
    unreadMsgs: 7,
    profileImage: chat1
  },
  {
    pp: chat4,
    contact: "Test3",
    msg: "Test chat ...",
    time: "yesterday",
    unreadMsgs: null,
    profileImage: chat1
  },
  {
    pp: chat4,
    contact: "Test4",
    msg: "Test chat ...",
    time: "yesterday",
    unreadMsgs: 7,
    profileImage: chat1
  },
  {
    pp: chat4,
    contact: "Test5",
    msg: "Test chat ...",
    time: "yesterday",
    unreadMsgs: null,
  },
  {
    pp: chat4,
    contact: "Test6",
    msg: "Test chat ...",
    time: "yesterday",
    unreadMsgs: 7,
  },
  {
    pp: chat4,
    contact: "Test7",
    msg: "Test chat ...",
    time: "yesterday",
    unreadMsgs: null,
  },
  {
    pp: chat4,
    contact: "Test8",
    msg: "Test chat ...",
    time: "yesterday",
    unreadMsgs: 7,
  },
];

export const messagesData = [
  {
    msg: "Hey! How's it going?",
    time: "9:30 am",
    sent: true,
  },
  {
    msg: "All good, just working on a new project.",
    time: "9:32 am",
    sent: true,
  },
  {
    msg: "That sounds interesting! What's the project about?",
    time: "9:35 am",
    sent: true,
  },
  {
    msg: "It's a weather app with real-time updates.",
    time: "9:40 am",
    sent: false,
    profileImage: chat2,
  },
  {
    msg: "Nice! Are you using any specific technologies?",
    time: "9:42 am",
    sent: false,
    profileImage: chat2,
  },
  {
    msg: "Yes, I'm using React and Node.js.",
    time: "9:50 am",
    sent: true,
  },
  {
    msg: "Awesome stack! How's the progress?",
    time: "10:05 am",
    sent: true,
  },
  {
    msg: "It's going well, just debugging a few issues.",
    time: "10:10 am",
    sent: false,
    profileImage: chat2,
  },
  {
    msg: "Debugging can be tricky. Need any help?",
    time: "10:15 am",
    sent: true,
  },
  {
    msg: "Not at the moment, but thanks for the offer!",
    time: "10:20 am",
    sent: false,
    profileImage: chat2,
  },
  {
    msg: "No problem. Let me know if you need anything.",
    time: "10:25 am",
    sent: true,
  },
  {
    msg: "Will do! Have a great day!",
    time: "10:30 am",
    sent: false,
    profileImage: chat2,
  },
  {
    msg: "You too! 😊",
    time: "10:35 am",
    sent: true,
  },
  {
    msg: "Check out this cool picture!",
    time: "10:40 am",
    sent: true,
    img: chat4,
    profileImage: chat4,
  }
];
