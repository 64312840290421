import React from "react";


const Dropdown = ({ onClearChat,onBlockContact, onUnblockContact, isBlocked }) => {
  return (
    <div className="bg-[#1d609b] text-black shadow-lg rounded-lg w-[200px] z-50">
      <ul>
        <li className="p-2 hover:bg-gray-200 cursor-pointer">Group Info</li>
        <li className="p-2 hover:bg-gray-200 cursor-pointer">Select Messages</li>
        <li
          className="p-2 hover:bg-gray-200 cursor-pointer"
          onClick={()=>{
            console.log('clear chat');
            onClearChat();
          }
        }
        >
          Clear Chat
        </li>
        <li className="p-2 hover:bg-gray-200 cursor-pointer">Mute Notification</li>
        <li className="p-2 hover:bg-gray-200 cursor-pointer flex items-center"
         onClick={() => {
          console.log('Block/Unblock clicked');
          isBlocked ? onUnblockContact() : onBlockContact();
        }}
        >
       {isBlocked ? 'Unblock' : 'Block'}
        </li>
      </ul>
    </div>
  );
};

export default Dropdown;