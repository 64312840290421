import React, { useState, useEffect } from "react";
import QRCodeLogin from "./QRCodeLogin";
import ChatDetails from "../components/ChatDetail";
import LeftMenu from "../components/LeftMenu";
import UserDetails from "../components/UserDetails";
import profilePicture from "../assets/images/chat4.png";
import img1 from "../assets/images/chat1.png";
import img2 from "../assets/images/chat2.png";
import LoadingScreen from "./LodingPage";
import DefaultPage from "../components/DefaultPage";

function ChatWeb() {
  const [leftMenuWidth, setLeftMenuWidth] = useState(30);
  const [isResizing, setIsResizing] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showQR, setShowQR] = useState(true);
  const [dataFromLeftMenu, setDataFromLeftMenu] = useState(null);

  const handleDataFromLeftMenu = (data) => {
    setDataFromLeftMenu(data);
    console.log("chatweb: ", data);
  };

  useEffect(() => {
    handleDataFromLeftMenu(null);
  }, []);

  const user = {
    name: "Chanuka",
    profilePicture: profilePicture,
    mobileNumber: "0233283323",
    about: "We are eSupport Technologies.",
  };
  const mediaImages = [
    { id: 1, title: "Vacation Photo", url: img1 },
    { id: 2, title: "Birthday Video", url: img2 },
  ];
  const links = [
    { id: 1, title: "Example Link 1", url: "https://example.com" },
    { id: 2, title: "Example Link 2", url: "https://example.com" },
  ];

  const handleMouseDown = () => {
    setIsResizing(true);
  };

  const handleMouseMove = (e) => {
    if (!isResizing) return;
    const newWidth = (e.clientX / window.innerWidth) * 100;
    if (newWidth >= 20 && newWidth <= 50) {
      setLeftMenuWidth(newWidth);
    }
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  const handleProfileClick = () => {
    setShowUserDetails(true);
  };

  const handleCloseUserDetails = () => {
    setShowUserDetails(false);
  };

  const handleContinue = () => {
    setShowQR(false); // Hide QR code page
    setLoading(true); // Show loading screen
    // Simulate loading progress and then show chat web page
    const id = setTimeout(() => {
      setLoading(false);
      clearTimeout(id);
    }, 2000); // Adjust duration as needed
  };

  useEffect(() => {
    if (!loading) return;
    const id = setTimeout(() => {
      if (progress >= 100) setLoading(false);
      else {
        const increment = Math.floor(Math.random() * (10 + 1)) + 7;
        setProgress(progress + increment);
      }
    }, 300);

    return () => clearTimeout(id);
  }, [progress, loading]);

  return (
    <>
      {showQR ? (
        <QRCodeLogin onContinue={handleContinue} />
      ) : loading ? (
        <LoadingScreen progress={progress} />
      ) : (
        <div
          className="w-screen h-screen overflow-hidden flex"
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          {/* LeftMenu */}
          <div
            className="bg-[#111a21] h-full flex-shrink-0"
            style={{ width: `${leftMenuWidth}%` }}
          >
            <LeftMenu user={user} onSendContact={handleDataFromLeftMenu} />
          </div>

          {/* Divider */}
          <div
            className="w-1 bg-gray-600 cursor-col-resize"
            onMouseDown={handleMouseDown}
          ></div>

          {/* ChatDetail or UserDetails */}
          <div className="bg-[#222f35] flex-1 h-full">
            {showUserDetails ? (
              <UserDetails
                user={user}
                mediaImages={mediaImages}
                links={links}
                onClose={handleCloseUserDetails}
              />
            ) : (
              //<DefaultPage onProfileClick={handleProfileClick} user={user} />
              <ChatDetails onProfileClick={handleProfileClick} user={user} />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ChatWeb;
