import React, { useState, useEffect } from "react";
import RoundedBtn from "./common/Button";
import { MdPeopleAlt, MdAccountCircle } from "react-icons/md";
import { TbCircleDashed } from "react-icons/tb";
import { BsFillChatLeftTextFill } from "react-icons/bs";
import { RiChatNewLine } from "react-icons/ri";
import { HiDotsVertical } from "react-icons/hi";
import Chats from "./Chats";
import UserProfile from "./UserProfile";
import DropDownMenu from "./common/DropDownMenu";
import StatusList from "./StatusList";
import Settings from "./Settings";
import ContactsList from "./ContactList";
import Drawer from "react-modern-drawer";
import NewChat from "./NewChat";
import Archived from "./Archived";
import { GoCheckCircleFill } from "react-icons/go";

const LeftMenu = ({ user, onSendContact }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [profilePic, setProfilePic] = useState(user.profilePicture);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [dataFromChat, setDataFromChat] = useState(null);
  const [currentView, setCurrentView] = useState("chats");

  const [search, setSearch] = useState("");
  const [selectedChat, setSelectedChat] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);

  const handleDataFromChat = (data) => {
    setDataFromChat(data);
  };

  useEffect(() => {
    handleDataFromChat(null);
  }, []);

  const sendDataToMain = () => {
    const data = dataFromChat;
    onSendContact(data);
  };

  useEffect(() => {
    if (dataFromChat) {
      sendDataToMain();
    }
  }, [dataFromChat]);

  const handleDropdownClick = (event) => {
    setDropdownPosition({ top: event.clientY, left: event.clientX });
    setDropdownVisible(!dropdownVisible);
  };

  const handleCreateGroup = () => {
    setIsDrawerOpen(true);
  };

  const handleSelectContact = (contact) => {
    setSelectedContacts((prevSelected) =>
      prevSelected.includes(contact)
        ? prevSelected.filter((c) => c !== contact)
        : [...prevSelected, contact]
    );
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleChatClick = (contact) => {
    setSelectedChat(contact);
  };

  const handleLogout = () => {
    console.log("Logged out");
  };

  const handleNewChat = () => {
    setCurrentView("contacts");
  };

  const handleGroupFilter = () => {
    console.log("Group filter");
  };

  const handleStatus = () => {
    setCurrentView("status");
  };

  const handleProfileClick = () => {
    setIsModalOpen(true);
  };

  const updateProfilePicture = (pic) => {
    setProfilePic(pic);
  };

  const handleSettings = () => {
    setCurrentView("settings");
  };

  const handleArchivedClick = () => {
    setCurrentView("archived");
  };

  const dropdownOptions = [
    { label: "Create a New Group", onClick: handleCreateGroup },
    { label: "Settings", onClick: handleSettings },
    { label: "Logout", onClick: handleLogout },
  ];

  return (
    <div className="relative flex flex-col border-r border-neutral-700 w-100 h-screen">
      <UserProfile
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        profilePic={profilePic}
        onUpdateProfilePicture={updateProfilePicture}
        user={user}
      />

      <div className="flex justify-between items-center bg-[#202d33] h-[60px] p-3 relative">
        {profilePic ? (
          <img
            src={profilePic}
            alt="profile-picture"
            className="rounded-full w-[48px] h-[48px] cursor-pointer"
            onClick={handleProfileClick}
          />
        ) : (
          <MdAccountCircle
            className="text-[#8796a1] hover:bg-[#3c454c] hover:rounded-full w-[40px] h-[40px] cursor-pointer"
            onClick={handleProfileClick}
          />
        )}

        <div className="flex justify-between w-[180px]">
          <RoundedBtn
            icon={<MdPeopleAlt />}
            onClick={handleGroupFilter}
            title="Groups"
          />
          <RoundedBtn
            icon={<TbCircleDashed />}
            onClick={handleStatus}
            title="Status"
          />
          <RoundedBtn
            icon={<BsFillChatLeftTextFill />}
            onClick={() => setCurrentView("chats")}
            title="Chats"
          />
          <RoundedBtn
            icon={<RiChatNewLine />}
            onClick={handleNewChat}
            title="New Chat"
          />
          <RoundedBtn
            icon={<HiDotsVertical />}
            onClick={handleDropdownClick}
            title="Menu"
          />
        </div>

        {dropdownVisible && (
          <DropDownMenu
            options={dropdownOptions}
            position={dropdownPosition}
            onClose={() => setDropdownVisible(false)}
          />
        )}
      </div>

      {currentView === "chats" && (
        <Chats
          onSendData={handleDataFromChat}
          onArchivedClick={handleArchivedClick}
        />
      )}
      {currentView === "status" && <StatusList />}
      {currentView === "settings" && (
        <Settings
          profilePic={profilePic}
          handleProfileClick={handleProfileClick}
          handleLogout={handleLogout}
          user={user}
        />
      )}
      {currentView === "contacts" && (
        <NewChat
          search={search}
          handleSearchChange={handleSearchChange}
          handleChatClick={handleChatClick}
          selectedChat={selectedChat}
        />
      )}
      {currentView === "archived" && <Archived />}

      <Drawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        direction="left"
        className="drawer"
        style={{
          width: "400px",
          backgroundColor: "#111a21",
          backgroundColor: "#202d33",
        }}
      >
        <div className="bg-[#111a21] w-full h-full p-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-white text-lg">Select Contacts</h2>
            <button
              className="text-white text-lg"
              onClick={() => {
                setIsDrawerOpen(false);
                setSelectedContacts([]);
              }}
            >
              &times;
            </button>
          </div>
          <ContactsList
            search={search}
            handleChatClick={handleChatClick}
            selectedChat={selectedChat}
            isGroupMode={true}
            handleSelectContact={handleSelectContact}
            selectedContacts={selectedContacts}
          />
          {selectedContacts.length > 0 && (
            <button
              className="absolute bottom-5 right-5 text-emerald-500 text-5xl rounded-full"
              onClick={() => {
                setIsDrawerOpen(false);
                setSelectedContacts([]);
                console.log("Group created:", selectedContacts);
              }}
            >
              <GoCheckCircleFill />
            </button>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default LeftMenu;
