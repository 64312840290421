import React from 'react';
import { LuPinOff } from 'react-icons/lu';
import { MdKeyboardDoubleArrowUp, MdKeyboardDoubleArrowDown } from 'react-icons/md'; 

function PinnedMessageDialog({ message, onClose, onUnpin, onNext, onPrev, isLast, isFirst }) {
  return (
    <div className="absolute top-12 left-0 right-0 bg-[#005c4b] p-2 rounded-md shadow-lg z-10 w-full flex items-center justify-between">
      {/* Container for up and down arrows */}
      <div className="flex flex-col items-center space-y-2">
        {/* Up arrow */}
        <button 
          onClick={onPrev} 
          disabled={isFirst} 
          className={`text-white ${isFirst ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          <MdKeyboardDoubleArrowUp size={24} />
        </button>
        {/* Down arrow */}
        <button 
          onClick={onNext} 
          disabled={isLast} 
          className={`text-white ${isLast ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          <MdKeyboardDoubleArrowDown size={24} />
        </button>
      </div>

      <div className="flex-1 text-center">
        <p className="text-white overflow-auto">{message}</p>
      </div>

      <div className="flex items-center space-x-2">
        {/* Unpin button with icon */}
        <button onClick={onUnpin} className="text-red-500">
          <LuPinOff size={24} />
        </button>
      </div>
    </div>
  );
}

export default PinnedMessageDialog;