import React, { useState, useEffect } from "react";
import { chatsData } from "../data/whatsapp";
import Chat from "./Chat";

const ContactsList = ({
  search,
  handleChatClick,
  selectedChat,
  isGroupMode,
  handleSelectContact,
  selectedContacts,
}) => {
  const sortedChatsData = chatsData
    .filter((chat) => /^[a-zA-Z\s]+$/.test(chat.contact))
    .sort((a, b) => a.contact.localeCompare(b.contact));

  const [chats, setChats] = useState(sortedChatsData);

  useEffect(() => {
    const filteredChats = sortedChatsData.filter((chat) =>
      chat.contact.toLowerCase().includes(search.toLowerCase())
    );

    setChats(filteredChats);
  }, [search]);

  const groupedChats = chats.reduce((acc, chat) => {
    const firstLetter = chat.contact.charAt(0).toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(chat);
    return acc;
  }, {});

  return (
    <div className="flex flex-col overflow-y-scroll cursor-pointer h-100 scrollbar-none">
      <div className="flex flex-col space-y-2 mt-1">
        {Object.keys(groupedChats).map((letter) => (
          <div key={letter}>
            <div className="text-[#8796a1] text-sm font-light px-2 py-0 mb-0 bg-[#202d33]">
              {letter}
            </div>
            {groupedChats[letter].map((chat) => (
              <div
                key={chat.id}
                className="flex items-center justify-between px-2 py-1"
              >
                <Chat
                  pp={chat.pp}
                  contact={chat.contact}
                  active={selectedChat === chat.contact}
                  onClick={() => handleChatClick(chat.contact)}
                  context="contactsList"
                />
                {isGroupMode && (
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-emerald-600 bg-emerald-100 border-emerald-300 rounded focus:ring-emerald-500 dark:focus:ring-emerald-600 dark:ring-offset-emerald-800 focus:ring-2 dark:bg-emerald-700 dark:border-emerald-600"
                    checked={selectedContacts.includes(chat.contact)}
                    onChange={() => handleSelectContact(chat.contact)}
                  />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactsList;
