import React, { useState } from "react";
import { MdAccountCircle } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import {
  BiLockAlt,
  BiBell,
  BiHelpCircle,
  BiChevronRight,
} from "react-icons/bi";
import Account from "./SettingsOptions/Account";
import Privacy from "./SettingsOptions/Privacy";
import Notifications from "./SettingsOptions/Notifications";
import Help from "./SettingsOptions/Help";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";

const Settings = ({ profilePic, handleProfileClick, handleLogout, user }) => {
  const [search, setSearch] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerComponent, setDrawerComponent] = useState(null);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const menuItems = [
    {
      icon: <MdAccountCircle />,
      label: "Account",
      component: <Account onBack={() => setIsDrawerOpen(false)} />,
      className: "text-gray-300 text-2xl",
    },
    {
      icon: <BiLockAlt />,
      label: "Privacy",
      component: <Privacy onBack={() => setIsDrawerOpen(false)} />,
      className: "text-gray-300 text-2xl",
    },
    {
      icon: <BiBell />,
      label: "Notifications",
      component: <Notifications onBack={() => setIsDrawerOpen(false)} />,
      className: "text-gray-300 text-2xl",
    },
    {
      icon: <BiHelpCircle />,
      label: "Help",
      component: <Help onBack={() => setIsDrawerOpen(false)} />,
      className: "text-gray-300 text-2xl",
    },
    {
      icon: <FiLogOut />,
      label: "Log out",
      action: handleLogout,
      className: "text-red-600 text-2xl font-semibold",
      textClass: "text-red-600 mb-2",
    },
  ];

  const filteredMenuItems = menuItems.filter((item) =>
    item.label.toLowerCase().includes(search.toLowerCase())
  );

  const handleMenuItemClick = (item) => {
    if (item.action) {
      item.action();
    } else {
      setDrawerComponent(item.component);
      setIsDrawerOpen(true);
    }
  };

  return (
    <div className="flex flex-col overflow-y-scroll h-full">
      <h1 className="text-white text-2xl p-2 font-semibold">Settings</h1>
      <div className="flex justify-between items-center h-[60px] px-2 -mt-2">
        <input
          type="text"
          placeholder="Search settings"
          className="rounded-lg bg-[#202d33] text-[#8796a1] text-sm font-light outline-none p-2 px-4 py-2 w-full h-[35px] placeholder:text-[#8796a1] placeholder:text-sm placeholder:font-light"
          value={search}
          onChange={handleSearchChange}
        />
      </div>
      <div className="flex flex-col items-start p-4 font-light -mt-2">
        <div className="flex items-center space-x-4 mb-6">
          {profilePic ? (
            <img
              src={profilePic}
              alt="profile-picture"
              className="rounded-full w-[48px] h-[48px] cursor-pointer"
              onClick={handleProfileClick}
            />
          ) : (
            <MdAccountCircle
              className="text-[#8796a1] hover:bg-[#3c454c] hover:rounded-full w-[48px] h-[48px] cursor-pointer"
              onClick={handleProfileClick}
            />
          )}
          <div>
            <p className="text-white text-lg font-light">{user.name}</p>
            <p className="text-[#8796a1] text-sm">{user.about}</p>
          </div>
        </div>
        {filteredMenuItems.map((item, index) => (
          <div
            key={index}
            className={`flex items-center w-full p-3 hover:bg-[#202d33] cursor-pointer border-b border-gray-700 space-y-2 ${
              item.className ? item.className : ""
            }`}
            onClick={() => handleMenuItemClick(item)}
          >
            {item.icon}
            <p
              className={`text-base ml-4 ${
                item.textClass ? item.textClass : "text-gray-300 mt-0"
              }`}
            >
              {item.label}
            </p>
            <BiChevronRight className="text-gray-400 ml-auto" />
          </div>
        ))}
      </div>
      <Drawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        direction="left"
        className="drawer"
        style={{ width: "400px", backgroundColor: "#111a21" }}
      >
        {drawerComponent}
      </Drawer>
    </div>
  );
};

export default Settings;
